/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

/*
  Hides Sitecore Experience Editor markup,
  if you run the app in connected mode while the Sitecore cookies
  are set to edit mode.
*/
.scChromeData,
.scpm {
  display: none !important;
}
.lazyload-wrapper,
.lazyload-placeholder {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
.footer-sponsor div.lazyload-wrapper {
  position: relative;
}
.accordion-item {
  overflow: hidden;
  transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
  height: auto;
  max-height: 9999px;
  float: left;
}
.accordion-item.collapsed {
  max-height: 0;
  transition: max-height 1s cubic-bezier(0, 1, 0, 1);
}
